/* eslint-disable no-console */
import { useRouter } from "next/dist/client/router";

const NotFound = (): null => {
    const router = useRouter();

    if (typeof window !== "undefined") {
        router.push("/devices");
    }

    return null;
};

export default NotFound;
